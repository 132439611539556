<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_information')"
                        :isFilter="false"
                        :actions="actions"
                        :is-back-button="true"
                        :back-button="backButton"
                        :selected-menu="selectedMenu"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_information')"
                              :isFilter="false"
                              :actions="actions"
                              :is-back-button="true"
                              :back-button="backButton"
                              :selected-menu="selectedMenu"
                />
            </template>
            <div class="d-flex flex-column flex-lg-row">
                <div class="mr-lg-5">
                    <info-page :studentProgram="studentProgram" :editMode="true"/>
                </div>
                <div class="mr-lg-5">
                    <menu-page v-model="selectedMenu"
                               :studentProgram="studentProgram"
                               :menu-data="menuData"/>
                    <b-dropdown
                        :text="computeDropdownMenuName"
                        block
                        split
                        split-variant="outline-primary"
                        variant="primary"
                        class="d-block d-lg-none secondary-border-lighter btn-outline-secondary mb-3"
                    >
                        <b-dropdown-item v-for="menu in menuData"
                                         :key="'dropdownMenu'+menu.slug"
                                         @click="selectedMenu = menu.slug"
                                         v-if="(menu.permission=='' || checkPermission(menu.permission)) && menu.show">
                            {{ menu.title }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="flex-grow-1 w-100 w-lg-0" v-if="studentProgram && studentProgram.id">
                    <general-information v-if="selectedMenu == 'general_information'"
                                         :studentProgram="studentProgram"
                    />
                    <identity-information v-if="selectedMenu == 'identity_information'"
                                          @updatedData="getStudent"
                                          :studentProgram="studentProgram"
                                          :editMode="editMode"
                    />
                    <foreign-student-credential v-if="selectedMenu == 'foreign_student_credentials'"
                                                @updatedData="getStudent"
                                                :studentProgram="studentProgram"
                                                :editMode="editMode"
                    />
                    <address-information v-if="selectedMenu == 'address_information'"
                                         @updatedData="getStudent"
                                         :studentProgram="studentProgram"
                                         :editMode="editMode"
                    />
                    <!-- <family-information v-if="selectedMenu == 'family_information'"
                                        @updatedData="getStudent"
                                        :studentProgram="studentProgram"
                                        :editMode="editMode"
                    />

                    <military-information v-if="selectedMenu == 'military_information'"
                                          @updatedData="getStudent"
                                          :studentProgram="studentProgram"
                                          :editMode="editMode"
                    /> -->
                    <health-information v-if="selectedMenu == 'health_information'"
                                        @updatedData="getStudent"
                                        :studentProgram="studentProgram"
                                        :editMode="editMode"
                    />
                    <university-information v-if="selectedMenu == 'university_information'"
                                            @updatedData="getStudent"
                                            :studentProgram="studentProgram"
                                            :editMode="editMode"
                    />
                    <contact-information v-if="selectedMenu == 'contact_information'"
                                         @updatedData="getStudent"
                                         :studentProgram="studentProgram"
                                         :editMode="editMode"
                    />
                    <score-information v-if="selectedMenu == 'score_information'"
                                       :studentProgram="studentProgram"
                                       :editMode="editMode"
                    />
                    <student-education v-if="selectedMenu == 'student_education'"
                                       :studentProgram="studentProgram"
                                       :editMode="editMode"
                    />
                    <undergraduate-vertical-transfer-information
                        v-if="selectedMenu == 'undergraduate_vertical_transfer_information'"
                        @updatedData="getStudent"
                        :studentProgram="studentProgram"
                        :editMode="editMode"
                    />
                    <documents v-if="selectedMenu == 'documents'"
                               :studentProgram="studentProgram"
                               :editMode="editMode"
                    />
                    <missing-document v-if="selectedMenu == 'missing_document'"
                                      @updatedData="getStudent"
                                      :studentProgram.sync="studentProgram"
                                      :editMode="editMode"
                    />
                    <note v-if="selectedMenu == 'notes'"
                          :studentProgram="studentProgram"
                          :editMode="editMode"
                    />
                    <discipline-process v-if="selectedMenu == 'discipline_process'"
                                        :studentProgram="studentProgram"
                                        :editMode="editMode"
                    />
                </div>
            </div>
            <CommonModal ref="DocumentModal" size="lg" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('documents') }}
                </template>
                <template v-slot:CommonModalContent>
                    <div class="mb-2 border rounded p-2 text-center bg-dark text-white">
                        {{ user.student_number }} - {{ user.name }} {{ user.surname }}
                    </div>
                    <b-row>
                        <b-col cols="6"
                               v-for="(item,itemKey) in computeDocuments"
                               :key="'document'+itemKey"
                               v-if="checkPermission(item.permission)">
                            <b-button-group class="mt-2 w-100">
                                <b-button v-for="(lang, langKey) in item.language" :key="'lang' + langKey"
                                    variant="outline-dark" @click="item.callback(lang, openNewTab)">
                                    {{ item.text }}
                                </b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>
                </template>
            </CommonModal>
            <CommonModal ref="UserInfoModal" size="lg" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('username_info_update') }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="usernameForm">
                        <b-row>
                            <b-col md="6">
                                <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('name')">
                                        <b-form-input v-model="usernameForm.name"
                                                      :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="surname" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('surname')">
                                        <b-form-input v-model="usernameForm.surname"
                                                      :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="12" class="mb-4">
                                <b-form-checkbox
                                    v-model="usernameForm.manuel"
                                    name="check-button"
                                    switch>
                                    {{$t('username_update_manuel_switch')}}
                                </b-form-checkbox>
                            </b-col>
                            <b-col md="6" v-if="usernameForm.manuel">
                                <ValidationProvider name="username" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('username')">
                                        <b-form-input v-model="usernameForm.username"
                                                      :class="errors[0] ? 'is-invalid':''">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="12">
                                <b-button @click="changeUsername" variant="primary">{{$t('change')}}</b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal ref="PostponementCertModal" size="md">
                <template v-slot:CommonModalTitle>
                    {{ $t(postponementForm.military_dispatch_status) }}
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="postponementForm">
                            <b-row v-if="postponementForm.military_dispatch_status == 'delay_1'">
                                <b-col md="12">
                                    <ValidationProvider name="military_branch" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('military_branch_to')">
                                            <b-form-input v-model="postponementForm.military_branch"
                                                          :class="errors[0] ? 'is-invalid':''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_start_date" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('undergraduate_start_date')">
                                            <v-date-picker
                                                v-model="postponementForm.graduate_start_date"
                                                :locale="$i18n.locale"
                                                is-expanded
                                                :masks="masks"
                                                :popover="{ 'visibility': 'click' }"
                                            >
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="date_of_postponement" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('delay_do_date')">
                                            <v-date-picker
                                                v-model="postponementForm.date_of_postponement"
                                                :locale="$i18n.locale"
                                                is-expanded
                                                :masks="masks"
                                                :popover="{ 'visibility': 'click' }"
                                            >
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row v-if="postponementForm.military_dispatch_status == 'extension'">
                                <b-col md="12">
                                    <ValidationProvider name="military_branch" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('military_branch_to')">
                                            <b-form-input v-model="postponementForm.military_branch"
                                                          :class="errors[0] ? 'is-invalid':''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_start_date" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('undergraduate_start_date')">
                                            <v-date-picker
                                                v-model="postponementForm.graduate_start_date"
                                                :locale="$i18n.locale"
                                                is-expanded
                                                :masks="masks"
                                                :popover="{ 'visibility': 'click' }"
                                            >
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_leave_date" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('undergraduate_leave_date')">
                                            <v-date-picker
                                                v-model="postponementForm.graduate_leave_date"
                                                :locale="$i18n.locale"
                                                is-expanded
                                                :masks="masks"
                                                :popover="{ 'visibility': 'click' }"
                                            >
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="date_of_postponement" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('delay_do_date')">
                                            <v-date-picker
                                                v-model="postponementForm.date_of_postponement"
                                                :locale="$i18n.locale"
                                                is-expanded
                                                :masks="masks"
                                                :popover="{ 'visibility': 'click' }"
                                            >
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="reason_for_cancel" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('cancel_reason_graduation')">
                                            <b-form-input v-model="postponementForm.reason_for_cancel"
                                                          :class="errors[0] ? 'is-invalid':''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="reason_for_extension" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('reason_of_extension')">
                                            <b-form-input v-model="postponementForm.reason_for_extension"
                                                          :class="errors[0] ? 'is-invalid':''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('explanation')">
                                            <b-form-textarea rows="4"
                                                             v-model="postponementForm.explanation"></b-form-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row v-if="postponementForm.military_dispatch_status == 'cancel'">
                                <b-col md="12">
                                    <ValidationProvider name="military_branch" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('military_branch_to')">
                                            <b-form-input v-model="postponementForm.military_branch"
                                                          :class="errors[0] ? 'is-invalid':''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_start_date" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('undergraduate_start_date')">
                                            <v-date-picker
                                                v-model="postponementForm.graduate_start_date"
                                                :locale="$i18n.locale"
                                                is-expanded
                                                :masks="masks"
                                                :popover="{ 'visibility': 'click' }"
                                            >
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="graduate_leave_date" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('undergraduate_leave_date')">
                                            <v-date-picker
                                                v-model="postponementForm.graduate_leave_date"
                                                :locale="$i18n.locale"
                                                is-expanded
                                                :masks="masks"
                                                :popover="{ 'visibility': 'click' }"
                                            >
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="date_of_postponement" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('delay_do_date')">
                                            <v-date-picker
                                                v-model="postponementForm.date_of_postponement"
                                                :locale="$i18n.locale"
                                                is-expanded
                                                :masks="masks"
                                                :popover="{ 'visibility': 'click' }"
                                            >
                                                <template
                                                    v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="reason_for_cancel" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('cancel_reason_graduation')">
                                            <b-form-input v-model="postponementForm.reason_for_cancel"
                                                          :class="errors[0] ? 'is-invalid':''">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('explanation')">
                                            <b-form-textarea rows="4"
                                                             v-model="postponementForm.explanation"></b-form-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="d-flex justify-content-between">
                                    <b-button variant="primary" @click="postponementAction">{{$t('print')}}</b-button>
                                    <b-button variant="outline-dark" @click="closePostponementModal">{{$t('cancel')}}</b-button>
                                </b-col>
                            </b-row>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="addSemester" size="xl">
                <template v-slot:CommonModalTitle>
                    {{ $t('semester_add') }}
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="semesterForm">
                            <b-row>
                                <b-col md="6">
                                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('semester')">
                                            <semesters-selectbox
                                                :validate-error="errors[0]" v-model="semesterForm.semester_id"
                                            ></semesters-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="semester_status" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('semester_status')">
                                            <parameter-selectbox
                                                :validate-error="errors[0]" code="semester_statuses" v-model="semesterForm.semester_status"
                                            ></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6">
                                    <ValidationProvider name="class" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('class')">
                                            <parameter-selectbox
                                                :validate-error="errors[0]" code="classes" v-model="semesterForm.class"
                                            ></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="12">
                                    <b-button @click="addSemester" variant="primary">{{$t('semester_add')}}</b-button>
                                </b-col>
                            </b-row>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Page
import AddressInformation from './addressInformation/Index';
import ContactInformation from './contactInformation/Index';
import DisciplineProcess from './disciplineProcess/Index'
import Documents from './documents/Index';
import ForeignStudentCredential from './foreignStudentCredential/Index';
import GeneralInformation from './generalInformation/Index';
import HealthInformation from './healthInformation/Index';
import IdentityInformation from './identityInformation/Index';
import InfoPage from './InfoPage';
import MenuPage from './MenuPage';
import MissingDocument from './missingDocument/Index';
import Note from './note/Index';
import ScoreInformation from './scoreInformation/Index';
import StudentEducation from './studentEducation/Index';
import UndergraduateVerticalTransferInformation from './undergraduateVerticalTransferInformation/Index';
import UniversityInformation from './universityInformation/Index';

// Services
import StudentProgramService from '@/services/StudentProgramService';
import StudentSemesterService from '@/services/StudentSemesterService';

// Data
import documentData from '@/modules/students/data/Documents';
import menuData from '@/modules/students/data/Menus';

// Component
import CommonModal from '@/components/elements/CommonModal';
import {EventBus} from '@/main'
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import UserService from "../../../../services/UserService";

export default {
    name: "StudentDetailPage",
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        ValidationObserver,
        ValidationProvider,

        AddressInformation,
        ContactInformation,
        DisciplineProcess,
        Documents,
        ForeignStudentCredential,
        GeneralInformation,
        HealthInformation,
        IdentityInformation,
        InfoPage,
        MenuPage,
        MissingDocument,
        Note,
        ScoreInformation,
        StudentEducation,
        UndergraduateVerticalTransferInformation,
        UniversityInformation,
        SemestersSelectbox,
        ParameterSelectbox,
        CommonModal
    },
    metaInfo() {
        return {
            title: this.$t('student')
        }
    },
    data() {
        return {
            studentProgramId: null,
            studentProgram: {},
            user: {},

            usernameForm: {
                name: null,
                surname: null,
                username: null,
                manuel: false
            },
            formProcess: null,
            menuData: null,
            selectedMenu: 'general_information',
            actions: [
                {
                    text: this.$t('payment_info'),
                    class: 'ri-money-dollar-circle-line',
                    permission: 'customer_customerpayments',
                    callback: () => {
                        if(this.checkPermission('paymentplan_create')){
                            this.$router.push('/customers/payments/student/' + this.studentProgram.id);
                        }
                        else {
                            this.$router.push('/customers/payments/view/student/' + this.studentProgram.id);
                        }
                    }
                },
                {
                    text: this.$t('course_registration'),
                    class: 'ri-book-line',
                    permission: 'courseregistration_getslot',
                    callback: () => {
                        this.$router.push('/course/registration/' + this.studentProgram.id)
                    }
                },
                {
                    text: this.$t('slots'),
                    class: 'ri-list-check',
                    permission: 'studentprogram_studentslot',
                    callback: () => {
                        this.$router.push('/student/programs/' + this.studentProgram.id + '/slots')
                    }
                },
                {
                    text: this.$t('documents'),
                    class: 'ri-file-list-line',
                    permission: 'studentprogram_show',
                    callback: () => {
                        this.showDocumentModal();
                    }
                },
                {
                    text: this.$t('username_info_update'),
                    class: 'ri-shield-user-line',
                    permission: 'user_updatepersonalinformation',
                    callback: () => {
                        this.showUserInfoModal();
                    }
                },
                {
                    text: this.$t('semester_add'),
                    permission: 'studentsemester_store',
                    callback: () => {
                        this.$refs.addSemester.$refs.commonModal.show()
                    }
                }
            ],
            editMode: true,
            postponementForm: {

            },

            masks: {
                input: "DD-MM-YYYY",
            },
            semesterForm: {},
            openNewTab: true
        }
    },
    async created() {
        this.studentProgramId = this.$route.params.id;
        // Student Check
        if (this.studentProgramId) {
            await this.getStudent()
            this.menuData = menuData(null, this.studentProgram);
            this.selectedDefaultValue()
        } else {
            this.$router.push('/404');
        }


    },
    computed: {
        computeDropdownMenuName: {
            get() {
                let selected = (this.menuData || []).find(item => item.slug == this.selectedMenu)
                return selected ? this.toUpperCase(selected.title) : '';
            },
            set() {

            }
        },
        computeDocuments() {
            let documents = [];

            if (this.user && this.user.id && this.user.student_number) {
                documents = documentData(this.user)
            }
            return documents;
        }
    },
    methods: {
        getStudent() {
            return StudentProgramService.get(this.studentProgramId)
                .then(response => {
                    this.studentProgram = response.data.data;
                    this.user = {
                        id: this.studentProgram.id,
                        student_number: this.studentProgram.student_number,
                        name: this.studentProgram.name,
                        surname: this.studentProgram.surname,
                        gender: this.studentProgram.gender,
                        nationality_code: this.studentProgram.nationality_code,
                        program_level: this.studentProgram.program_level,
                        registration_type: this.studentProgram.registration_type,
                        registration_type_text: this.studentProgram.registration_type_text,
                        student_status: this.studentProgram.student_status,
                    }
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        backButton() {
            this.$router.push('/students');
        },

        // Clear
        formClear() {
            this.formProcess = null
        },

        // Documents
        showDocumentModal() {
            this.$refs.DocumentModal.$refs.commonModal.show()
        },

        selectedDefaultValue() {
            let selected = this.menuData.find(item => item.slug == this.selectedMenu)
            this.computeDropdownMenuName = selected ? this.toUpperCase(selected.title) : '';
        },
        showUserInfoModal() {
            this.usernameForm.name = this.user.name
            this.usernameForm.surname = this.user.surname
            this.$refs.UserInfoModal.$refs.commonModal.show()
        },

        async changeUsername(){
            const isValid = await this.$refs.usernameForm.validate();
            if (isValid) {
                let formData = {
                    name: this.usernameForm.name,
                    surname: this.usernameForm.surname
                }
                this.usernameForm.manuel ? formData.username = this.usernameForm.username : null
                UserService.updatePersonalInformation(formData, this.studentProgram.id).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$refs.UserInfoModal.$refs.commonModal.hide()
                }).catch(e => {
                    this.showErrors(e, this.$refs.usernameForm)
                })
            }
        },

        async postponementAction(){
            const isValid = await this.$refs.postponementForm.validate();
            if (isValid) {
                EventBus.$emit('postponementDataSend', this.postponementForm);
                this.postponementForm = {}
            }
        },

        closePostponementModal(){
            this.$refs.PostponementCertModal.$refs.commonModal.hide()
        },
        async addSemester() {
            const isValid = await this.$refs.semesterForm.validate()

            if (isValid) {
                this.semesterForm.student_program_id = this.user.id

                StudentSemesterService.store(this.semesterForm)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$emit('actionSuccess', true)

                        this.$refs.addSemester.$refs.commonModal.hide()
                    })
                    .catch((error) => {
                        if (error.status == 422) {
                            if (error.data.errors.student_program_id) {
                                this.$refs.semesterForm.errors.semester.push(error.data.errors.student_program_id[0])
                            }
                        }
                        else {
                            this.$toast.error(this.$t('api.' + error.data.message))
                        }
                    })

            }
        }
    },
    mounted() {
        EventBus.$on('closeDocumentModal', (event) => {
            this.$refs.DocumentModal.$refs.commonModal.hide()
        })

        EventBus.$on('openPostponementCertModal', (event) => {
            this.postponementForm.military_dispatch_status = event
            this.$refs.PostponementCertModal.$refs.commonModal.show()
        })

        EventBus.$on('closePostponementCertModal', (event) => {
            this.$refs.PostponementCertModal.$refs.commonModal.hide()
        })
    }
}
</script>

